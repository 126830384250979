<template>
  <div
    class="bg-white mb-1"
    style="border-radius: 5px; padding: 12px; box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.05);"
  >
    <h3 class="font-weight-bolder text-body">
      {{ $t(DISPATCH_TITLE) }}
    </h3>
    <b-form>
      <b-row>
        <b-col class="6 mt-1">
          <component
            :is="dispatch[field].type"
            v-for="field in [
              'receiving_warehouse',
              'receive_method',
            ]"
            :key="field"
            v-model="dispatchAndReturn[field]"
            v-bind="getProps(field, dispatch)"
          />
        </b-col>
        <b-col class="6 mt-1">
          <component
            :is="dispatch[field].type"
            v-for="field in ['dispatch_contact']"
            :key="field"
            v-model="dispatchAndReturn[field]"
            v-bind="getProps(field, dispatch, true, 'dispatch_contact', getParentValue)"
          >
            <template slot="after-input">
              <button-dashed
                class="ml-1 opacity-50 btnBorderRevert"
              >
                <feather-icon
                  icon="LAddButtonIcon"
                  class="cursor-pointer"
                  size="34"
                  @click="newDispatchContact"
                />
              </button-dashed>
            </template>
          </component>
          <!--          shipping_notes-->
          <component
            :is="dispatch[field].type"
            v-for="field in ['external_shipping_notes']"
            :key="field"
            v-model="dispatchAndReturn[field]"
            v-bind="getProps(field, dispatch, true, 'external_shipping_notes', getParentValue)"
          />
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>
<script>

import { BCol, BForm, BRow } from 'bootstrap-vue'
import { getProps } from '@/@core/utils/utils'
import ButtonDashed from '@core/components/b-button-dashed/ButtonDashed.vue'
import config from '../config'

export default {
  components: {
    ButtonDashed,
    BCol,
    BRow,
    BForm,
  },
  computed: {
    dispatchAndReturn() {
      return this.$store.state[this.MODULE_NAME].dispatchAndReturnForm
    },
    customerInformationModel() {
      return this.$store.state[this.MODULE_NAME].customerInformationForm
    },
  },
  methods: {
    // eslint-disable-next-line consistent-return
    getParentValue() {
      return this.dispatchAndReturn.receiving_warehouse
    },
    newDispatchContact() {
      const route = this.$router.resolve({ name: 'user-create' })
      window.open(route.href)
    },
  },
  setup() {
    const { MODULE_NAME, dispatch, DISPATCH_TITLE } = config()

    return {
      getProps,
      dispatch,
      MODULE_NAME,
      DISPATCH_TITLE,
    }
  },
}
</script>
