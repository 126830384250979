var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('portal',{attrs:{"to":"body-footer"}},[_c('div',{staticClass:"d-flex mt-2 pb-1 justify-content-between"},[_c('div',[(true)?_c('b-button',{staticClass:"cancelBtn font-medium-1 font-weight-bolder",attrs:{"variant":"outline-primary"},on:{"click":_vm.redirectList}},[_vm._v(" "+_vm._s(_vm.$t("Back to List"))+" ")]):_c('b-button',{staticClass:"cancelBtn font-medium-1 font-weight-bolder",attrs:{"variant":"outline-primary"},on:{"click":_vm.clearForm}},[_c('feather-icon',{attrs:{"icon":"LCancelIcon","size":"16"}}),_vm._v(" "+_vm._s(_vm.$t("Cancel"))+" ")],1)],1),_c('div',{staticClass:"d-flex",staticStyle:{"gap":"12px"}},[_c('b-button',{staticClass:"font-medium-1",staticStyle:{"background":"#6f1214 !important"},attrs:{"disabled":Boolean(!_vm.quote.id) || _vm.loadingFor.isLoading || _vm.isQuoteVoid},on:{"click":function($event){return _vm.submitByAction(_vm.DIRECT_REQUEST_FOR_QUOTE_ACTION_VOID)}}},[_vm._v(" "+_vm._s(_vm.$t(_vm.FORM_BUTTON_TITLE_VOID))+" ")]),_c('b-button',{staticClass:"font-medium-1",attrs:{"disabled":_vm.loadingFor.isLoading},on:{"click":_vm.printRFQForSupplierTemplate}},[_c('Printer',[_c('div',{attrs:{"id":"rfq","hidden":""}},[_c('RFQPrintTemplate')],1)]),_c('feather-icon',{attrs:{"icon":"LPrintIcon","size":"16"}}),_vm._v(" "+_vm._s(_vm.$t(_vm.FORM_BUTTON_TITLE_PRINT))+" ")],1),_c('b-button',{staticClass:"font-medium-1 p-0 d-flex justify-content-center align-items-center",staticStyle:{"width":"160px","min-height":"40px"},attrs:{"disabled":(Boolean(_vm.quote.id) && !_vm.isDraft) && !_vm.isQuoteSentByEmail || _vm.isQuoteVoid || _vm.loadingFor.isLoading},on:{"click":function($event){return _vm.submit(_vm.DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_DRAFT)}}},[(
            _vm.loadingFor.isLoading &&
              _vm.loadingFor.action === _vm.DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_DRAFT
          )?_c('b-spinner',{attrs:{"small":"","variant":"secondary"}}):_c('span',[_vm._v(" "+_vm._s(_vm.$t(_vm.FORM_BUTTON_TITLE_SAVE_AS_DRAFT))+" ")])],1),_c('b-button',{staticClass:"font-medium-1 p-0 d-flex justify-content-center align-items-center",staticStyle:{"width":"160px","min-height":"40px"},attrs:{"disabled":_vm.isQuoteVoid || (_vm.loadingFor.isLoading || _vm.isQuoteSentByEmail)},on:{"click":function($event){return _vm.submit(_vm.DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_SEND_EMAIL)}}},[(
            _vm.loadingFor.isLoading &&
              _vm.loadingFor.action ===
              _vm.DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_SEND_EMAIL
          )?_c('b-spinner',{attrs:{"small":"","variant":"secondary"}}):_c('span',[_vm._v(" "+_vm._s(_vm.$t(_vm.FORM_BUTTON_TITLE_SEND_BY_EMAIL))+" ")])],1),_c('b-button',{staticClass:"font-medium-1",staticStyle:{"width":"160px","min-height":"40px"},attrs:{"disabled":_vm.isQuoteVoid || _vm.loadingFor.isLoading,"variant":"success"},on:{"click":function($event){return _vm.submit(_vm.DIRECT_REQUEST_FOR_QUOTE_ACTION_QUOTE_RECEIVED)}}},[(
            _vm.loadingFor.isLoading &&
              _vm.loadingFor.action ===
              _vm.DIRECT_REQUEST_FOR_QUOTE_ACTION_QUOTE_RECEIVED
          )?_c('b-spinner',{attrs:{"small":"","variant":"secondary"}}):_c('span',[_vm._v(" "+_vm._s(_vm.$t(_vm.FORM_BUTTON_TITLE_QUOTE_RECEIVED))+" ")])],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }