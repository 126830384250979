<template>
  <div>
    <div>
      <h3 class="font-weight-bolder text-body">
        {{ $t(STOCK_ITEMS_TITLE) }}
      </h3>
    </div>
    <div class="d-flex justify-content-end">
      <button-dashed
        class="btnBorderRevert"
      >
        <feather-icon
          icon="LAddButtonIcon"
          class="cursor-pointer"
          size="34"
          @click="gotoNewPage('/catalog/create', $event, true)"
        />
      </button-dashed>

    </div>

    <l-draggable-table-list-collector
      ref="lTableRefStockItems"
      class="stock-items-table px-1"
      :is-footer="false"
      :module-name="MODULE_NAME"
      :fetched-data="fetchedRentals()"
      :table-columns="tableColumnsStockItems"
      :table-suggestion-configs="purchaseTableAutoSuggestTableColumns"
      :table-config-options="{
        endpoint: `${MODULE_NAME}/stockItems`
      }"
      :additional-object-for-selected-item="{
        amount: 1,
        isNoteOpen: false,
        notes: '',
      }"
      :is-drag-row="false"
      autosuggest-list-item-name="name"
      collection-of-list-item-i-d="id"
      @getCollectedList="getCollectedList"
      @rowHoveredHandler="rowHoveredHandler"
      @rowUnHoveredHandler="rowUnHoveredHandler"
    >
      <template #cell(actions)="{data}">
        <div>
          <div
            :id="`td-${data.id}`"
            class="d-flex"
            style="gap: 8px"
          >
            <feather-icon
              icon="LTrashIcon"
              size="30"
              style="padding: 4px"
              class="cursor-pointer border-dotted feather-trash-icon defaultIconColor"
              @click="remove(data)"
            />
            <feather-icon
              icon="LEyeIcon"
              size="24"
              class="cursor-pointer border-dotted feather-trash-icon defaultIconColor"
              @click="navigateToProduct(data, $event)"
            />
            <feather-icon
              icon="LNoteIcon"
              class="lightIcon cursor-pointer border-dotted featherIcon"
              size="30"
              style="padding: 4px; z-index: 1000"
            />
          </div>

        </div>
      </template>

      <template #below-actions="{ data }">
        <div
          v-show="data.isNoteOpen"
          class="position-absolute w-100 pl-1"
          style="bottom: 2px; max-width: 1000px; width: 100%; z-index: 10; left: 0"
        >
          <b-form-input
            :ref="`item-note-input-${data.id}`"
            v-model="data.notes"
            class="item-note-input"
            style="height: 30px"
          />
          <!--              @blur="noteHandler(item)"-->
        </div>
        <div
          v-show="!data.isNoteOpen && data.notes"
          class="position-absolute w-100 pl-1"
          style="bottom: 2px; min-width: 1000px; width: 100%; z-index: 10 ; left: 0; padding: 4px 15px; height: 30px"
        >
          {{ data.notes }}
        </div>
      </template>

      <template #cell(units)="{ data }">
        <div
          style="vertical-align: center"
        >
          <increment-decrement-input
            ref="incrementDecrementInputStockItems"
            :item="data"
            item-key-name-for-amount="amount"
            :on-index-comparison="data.id === getTableRowIndex"
            @onIncrementDecrementAction="onIncrementDecrementAction"
          >
            <template #switch-off-action>
              <div style="line-height: 20px">
                {{ data.amount ? data.amount : 1 }}
              </div>
            </template>
          </increment-decrement-input>

        </div>
      </template>
      <template #cell(name)="{ data }">
        <div class="cell-row-name">
          {{ getValueFromGivenObjectByKey(data, (data) => data.name ? data.name : data.substitute.name, '' ) }}
        </div>
      </template>
      <template #cell(mpn)="{ data }">
        {{ data.hasOwnProperty('detail') ? getValueFromGivenObjectByKey(data, 'detail.mpn' ) : getValueFromGivenObjectByKey(data, 'mpn' ) }}
      </template>
      <template #cell(sku)="{ data }">
        {{ getValueFromGivenObjectByKey(data, 'sku') }}
      </template>
      <template #cell(last_price)="{ data }">
        {{ formatCurrency(data) }}
      </template>
      <template #cell(last_price_date)="{ data }">
        {{ formatDate(data) }}
      </template>
      <template
        #foot(units)=""
      >
        <div
          class="d-flex justify-content-center"
          style="min-width: 230px; gap: 25px"
        >
          <span>
            {{ $t('TOTALS') }}
          </span>
          <span>
            {{ stockItemsTotalQuantity }}
          </span>
        </div>
      </template>
    </l-draggable-table-list-collector>
  </div>
</template>
<script>

import config from '@/views/main/orders/view/purchase/components/create/direct-request-for-quote/config'
import {
  sumTotalAmountOfGivenArrayByProperty,
  getValueFromGivenObjectByKey,
  formatNumberToCurrencyView,
} from '@core/utils/utils'
import { BFormInput } from 'bootstrap-vue'
import LDraggableTableListCollector from '@/views/components/LDraggableTableListCollector.vue'
import ButtonDashed from '@core/components/b-button-dashed/ButtonDashed.vue'
import { format } from 'date-fns'

export default {
  name: 'StockItems',
  components: {
    LDraggableTableListCollector,
    BFormInput,
    ButtonDashed,
  },
  props: {
    newQuoteProductId: {
      type: [String, Number],
      default: 0,
    },
  },
  data() {
    return {
      getTableRowIndex: null,
      stockItemsTotalQuantity: 0,
      theQuoteProductData: {},
    }
  },
  computed: {
    orderItemsTable() {
      return this.$store.state[this.MODULE_NAME].orderItemsTable
    },
  },
  mounted() {
    if (this.newQuoteProductId) {
      this.$store.dispatch(`${this.MODULE_NAME}/addToNewQuoteProductId`, {
        id: this.newQuoteProductId,
      }).then(res => {
        const { data } = res.data
        this.theQuoteProductData = data

        if (!this.orderItemsTable.products) {
        } else {
          const exists = this.orderItemsTable.products.some(product => product.id === this.theQuoteProductData.id)
          if (!exists) {
            this.orderItemsTable.products.push({
              ...this.theQuoteProductData,
              amount: 1,
            })
          }
        }
        this.onIncrementDecrementAction()
      }).catch(error => {
        console.error('Error fetching new quote product data:', error)
      })
    }
  },
  created() {
    if (this.orderItemsTable.products) {
      this.onIncrementDecrementAction()
    }
  },
  methods: {
    fetchedRentals() {
      return this.orderItemsTable.products
    },
    onIncrementDecrementAction() {
      this.stockItemsTotalQuantity = this.sumTotalAmountOfGivenArrayByProperty(this.fetchedRentals(), 'amount')
    },
    getCollectedList() {
      this.onIncrementDecrementAction()
    },
    rowHoveredHandler(rowData) {
      const { id, amount } = rowData
      this.getTableRowIndex = id
      this.$refs.incrementDecrementInputStockItems.setValue(amount ?? 1)
    },
    rowUnHoveredHandler() {
      this.getTableRowIndex = null
    },
    navigateToProduct({ id }, e) {
      this.gotoNewPage({ name: 'home-catalog-general-info', params: { id } }, e, true)
    },
    remove({ id }) {
      this.orderItemsTable.products = this.orderItemsTable.products.filter(item => item.id !== id)
      this.onIncrementDecrementAction()
    },
    formatCurrency(data) {
      const price = data?.rates?.[0]?.rate
      return price ? formatNumberToCurrencyView(price) : '-'
    },
    formatDate(data) {
      const date = data?.rates?.[0]?.updated_at
      return date ? format(new Date(date), 'MMMM dd, yyyy') : '-'
    },
    /* noteHandler(data) {
      const { id, isNoteOpen, notes } = data
      const tableRowTDS = document.getElementById(`td-${id}`)
      const item = this.orderItemsTable.products.find(product => product.id === id)

      if (isNoteOpen) {
        if (notes) {
          tableRowTDS.style.height = '75px'
        } else {
          tableRowTDS.style.height = 'auto'
        }
        item.isNoteOpen = false
        this.$refs.lTableRefStockItems.rowHoveredHandler(data)
      } else {
        tableRowTDS.style.height = '75px'
        item.isNoteOpen = true
        this.$refs.lTableRefStockItems.rowUnHoveredHandler()
      }
    }, */

  },
  setup() {
    const MODULE_NAME = 'purchase-cr'

    const {
      STOCK_ITEMS_TITLE,
      tableColumnsStockItems,
      purchaseTableAutoSuggestTableColumns,
    } = config()

    return {
      MODULE_NAME,
      STOCK_ITEMS_TITLE,
      tableColumnsStockItems,
      getValueFromGivenObjectByKey,
      sumTotalAmountOfGivenArrayByProperty,
      purchaseTableAutoSuggestTableColumns,
      formatNumberToCurrencyView,
    }
  },
}
</script>
<style lang="scss">
.stock-items-table {

  tbody td {
    vertical-align: top !important;
  }

  .line-btn  {
    display: none;
  }

  table td[aria-colindex="6"] {
    padding: 0 !important;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 38px;
    & > div {
      min-height: 100%;
    }
  }

}
</style>
