<template>
  <portal to="body-footer">
    <div class="d-flex mt-2 pb-1 justify-content-between">
      <div>
        <b-button
          v-if="true"
          class="cancelBtn font-medium-1 font-weight-bolder"
          variant="outline-primary"
          @click="redirectList"
        >
          {{ $t("Back to List") }}
        </b-button>
        <b-button
          v-else
          class="cancelBtn font-medium-1 font-weight-bolder"
          variant="outline-primary"
          @click="clearForm"
        >
          <feather-icon
            icon="LCancelIcon"
            size="16"
          />
          {{ $t("Cancel") }}
        </b-button>
      </div>
      <div
        class="d-flex"
        style="gap: 12px"
      >
        <b-button
          style="background: #6f1214 !important"
          class="font-medium-1"
          :disabled="Boolean(!quote.id) || loadingFor.isLoading || isQuoteVoid"
          @click="submitByAction(DIRECT_REQUEST_FOR_QUOTE_ACTION_VOID)"
        >
          {{ $t(FORM_BUTTON_TITLE_VOID) }}
        </b-button>
        <b-button
          class="font-medium-1"
          :disabled="loadingFor.isLoading"
          @click="printRFQForSupplierTemplate"
        >
          <Printer>
            <div
              id="rfq"
              hidden
            >
              <RFQPrintTemplate />
            </div>
          </Printer>
          <feather-icon
            icon="LPrintIcon"
            size="16"
          />
          {{ $t(FORM_BUTTON_TITLE_PRINT) }}
        </b-button>
        <b-button
          :disabled="(Boolean(quote.id) && !isDraft) && !isQuoteSentByEmail || isQuoteVoid || loadingFor.isLoading"
          style="width: 160px; min-height: 40px"
          class="font-medium-1 p-0 d-flex justify-content-center align-items-center"
          @click="submit(DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_DRAFT)"
        >
          <b-spinner
            v-if="
              loadingFor.isLoading &&
                loadingFor.action === DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_DRAFT
            "
            small
            variant="secondary"
          />
          <span v-else>
            {{ $t(FORM_BUTTON_TITLE_SAVE_AS_DRAFT) }}
          </span>
        </b-button>
        <b-button
          :disabled="isQuoteVoid || (loadingFor.isLoading || isQuoteSentByEmail)"
          style="width: 160px; min-height: 40px"
          class="font-medium-1 p-0 d-flex justify-content-center align-items-center"
          @click="submit(DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_SEND_EMAIL)"
        >
          <b-spinner
            v-if="
              loadingFor.isLoading &&
                loadingFor.action ===
                DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_SEND_EMAIL
            "
            small
            variant="secondary"
          />
          <span v-else>
            {{ $t(FORM_BUTTON_TITLE_SEND_BY_EMAIL) }}
          </span>
        </b-button>
        <b-button
          :disabled="isQuoteVoid || loadingFor.isLoading"
          variant="success"
          style="width: 160px; min-height: 40px"
          class="font-medium-1"
          @click="submit(DIRECT_REQUEST_FOR_QUOTE_ACTION_QUOTE_RECEIVED)"
        >
          <b-spinner
            v-if="
              loadingFor.isLoading &&
                loadingFor.action ===
                DIRECT_REQUEST_FOR_QUOTE_ACTION_QUOTE_RECEIVED
            "
            small
            variant="secondary"
          />
          <span v-else>
            {{ $t(FORM_BUTTON_TITLE_QUOTE_RECEIVED) }}
          </span>
        </b-button>
      </div>
    </div>
  </portal>
</template>

<script>
import { BButton, BSpinner } from 'bootstrap-vue'
import { scrollToError, mapDate } from '@core/utils/utils'
import { mapGetters } from 'vuex'
import mainConfig from '@/views/main/orders/view/purchase/config'
import Printer from '@/views/components/Printer/Printer.vue'
import jsonToFormData from '@/libs/jsonToFormData'
import RFQPrintTemplate
from '@/views/main/orders/view/purchase/components/create/rfq-for-supplier/components/RFQPrintTemplate.vue'
import config from '../config'

export default {
  name: 'FormBottom',
  components: {
    RFQPrintTemplate,
    BButton,
    BSpinner,
    Printer,
  },
  props: {
    formRef: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loadingFor: {
        isLoading: false,
        action: null,
      },
    }
  },
  computed: {
    ...mapGetters('purchase-cr', ['getTotalDataOfForms']),
    disabled() {
      return true
    },
    quote() {
      return this.$store.state[this.MODULE_NAME].quote
    },
    isQuoteSentByEmail() {
      return this.quote.state === 0 && this.quote.status === 4
    },
    isDraft() {
      return this.quote.state === 0 && this.quote.status === 1
    },
    isQuoteVoid() {
      return this.quote.state === 0 && this.quote.status === 5
    },
    customerInformationForm() {
      return this.$store.state[this.MODULE_NAME].customerInformationForm
    },
    dispatchAndReturnForm() {
      return this.$store.state[this.MODULE_NAME].dispatchAndReturnForm
    },
    orderItemsTable() {
      return this.$store.state[this.MODULE_NAME].orderItemsTable
    },
    orderItemsOtherCharges() {
      return this.$store.state[this.MODULE_NAME].orderItemsOtherChargesTable
    },
    onError() {
      return this.$store.state[this.MODULE_NAME].onError
    },
    purchaseFormClone() {
      return this.$store.state[this.MODULE_NAME_CLONE].purchaseFormClone
    },
    isFormChanged() {
      return (
        JSON.stringify(this.getTotalDataOfForms) !== this.purchaseFormClone
      )
    },
  },
  methods: {
    redirectList() {
      this.$router.push({ name: 'home-orders-purchase-cr-list' })
    },
    redirectToRFQSend(id, action) {
      if (action === this.DIRECT_REQUEST_FOR_QUOTE_ACTION_QUOTE_RECEIVED) {
        this.$router.push({
          name: 'home-orders-purchase-register-received-quote-from-supplier',
          params: { id },
        })
      } else if (action === this.DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_DRAFT) {
        if (!this.quote.id) {
          this.$router.push({
            name: 'direct-request-for-quote',
            params: { id },
          })
        } else {
          window.location.reload()
        }
      } else {
        this.$router.push({
          name: 'home-orders-purchase-rfq-for-supplier',
          params: { id },
        })
      }
    },
    printRFQForSupplierTemplate() {
      this.$htmlToPaper('rfq', {
        autoClose: false,
      })
    },
    submit(action) {
      if (action === this.DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_DRAFT) {
        setTimeout(() => {
          this.formRef.purchaseQuoteForm.reset()
        }, 0)
        setTimeout(() => {
          this.submitLogic(action)
        }, 1)
      } else {
        this.formRef.purchaseQuoteForm.validate().then(success => {
          if (success) {
            this.submitLogic(action)
          } else {
            scrollToError(this, this.formRef.purchaseQuoteForm)
          }
        })
      }
    },

    // eslint-disable-next-line consistent-return
    submitLogic(action) {
      this.loadingFor = {
        isLoading: true,
        action,
      }
      this.$emit('onSubmitSuccess')
      this.onSubmit = true
      const {
        customerInformationForm,
        dispatchAndReturnForm,
        orderItemsTable,
        orderItemsOtherCharges,
      } = this

      const totalData = {
        action,
        supplier_id: customerInformationForm.customer_id,
        other_charges: orderItemsOtherCharges.otherCharges.map(({
          id, unit, name,
        }) => ({
          id,
          name,
          unit,
        })),
        billing_contact_id: customerInformationForm.billing_contact?.id,
        required_by: customerInformationForm.required_by ? mapDate(customerInformationForm.required_by) : null,
        internal_order_reference: customerInformationForm.internal_order_reference,
        external_order_notes: customerInformationForm.external_order_notes,
        payment_term_id: customerInformationForm.payment_term_id?.id,
        receiving_warehouse_id: dispatchAndReturnForm.receiving_warehouse?.id,
        dispatch_method_id: dispatchAndReturnForm.receive_method?.id,
        dispatch_contact_id: dispatchAndReturnForm.dispatch_contact?.id,
        external_shipping_notes: dispatchAndReturnForm.external_shipping_notes,
        attachments: customerInformationForm.attachments.filter(({ id }) => !id),
      }

      if (!this.quote.id) {
        totalData.products = orderItemsTable.products.map(
          ({ id, amount, notes }) => ({ id, amount, notes: notes ?? '' }),
        )
      }

      let dataForSending = jsonToFormData(totalData)

      if (this.quote.id) {
        // eslint-disable-next-line no-underscore-dangle
        totalData._method = 'PUT'
        totalData.id = this.quote.id
        // eslint-disable-next-line no-unused-expressions
        totalData.products = this.orderItemsTable.products.map(
          ({
            id, amount, price, available_on, discount,
          }) => ({
            id,
            amount,
            price: price ?? 0,
            available_on: mapDate(available_on),
            discount,
          }),
        )

        dataForSending = {
          id: this.quote.id,
          data: jsonToFormData(totalData),
        }
      }
      this.sendNotification(
        this,
        dataForSending,
        `${this.MODULE_NAME}/${this.quote.id ? 'createByID' : 'create'}`,
      )
        .then(({ data }) => {
          const { id } = data.data

          this.redirectToRFQSend(id, action)
          this.$emit('clear')
        })
        .catch(err => {
          this.$store.commit(`${this.MODULE_NAME}/SET_ON_ERROR`, err.response?.data)
        }).finally(() => {
          this.loadingFor = {
            isLoading: false,
            action: null,
          }
        })
    },

    submitByAction(action) {
      const { id } = this.quote

      this.loadingFor = {
        isLoading: true,
        action,
      }

      this.sendNotification(this, { id, body: { id, action } }, `${this.MODULE_NAME}/patch`, `${this.FORM_BUTTON_TITLE_QUOTE_RECEIVED} successfully`)
        .then(({ data }) => {
          // eslint-disable-next-line no-shadow
          const { id } = data.data

          if (action === this.DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_SEND_EMAIL) {
            this.$router.push({ name: 'home-orders-purchase-rfq-for-supplier', params: { id } })
          } else if (action === this.DIRECT_REQUEST_FOR_QUOTE_ACTION_VOID) {
            this.$router.push({ name: 'home-orders-purchase-cr-list' })
          } else if (action === 'back_rfq') {
            this.$store.commit(`${this.MODULE_NAME}/GET`, {})
            this.$router.push({ name: 'home-orders-purchase-cr-list' })

            // eslint-disable-next-line no-shadow
            // this.$store.dispatch(`${this.MODULE_NAME}/get`, id).then(data => {
            //   this.mapDataOnGetItem(data.data, this)
            // })
          } else {
            this.$router.push({ name: 'home-orders-purchase-register-received-quote-from-supplier', params: { id } })
          }
        }).finally(() => {
          this.loadingFor = {
            isLoading: false,
            action: null,
          }
        })
    },
  },
  setup() {
    const {
      mapDataOnGetItem,
      FORM_BUTTON_TITLE_VOID,
      FORM_BUTTON_TITLE_PRINT,
      FORM_BUTTON_TITLE_SAVE_AS_DRAFT,
      FORM_BUTTON_TITLE_SEND_BY_EMAIL,
      FORM_BUTTON_TITLE_QUOTE_RECEIVED,
      DIRECT_REQUEST_FOR_QUOTE_ACTION_VOID,
      DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_DRAFT,
      DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_SEND_EMAIL,
      DIRECT_REQUEST_FOR_QUOTE_ACTION_QUOTE_RECEIVED,
    } = config()

    const {
      MODULE_NAME,
      MODULE_NAME_CLONE,
    } = mainConfig()

    return {
      MODULE_NAME,
      mapDataOnGetItem,
      MODULE_NAME_CLONE,
      FORM_BUTTON_TITLE_VOID,
      FORM_BUTTON_TITLE_PRINT,
      FORM_BUTTON_TITLE_SAVE_AS_DRAFT,
      FORM_BUTTON_TITLE_SEND_BY_EMAIL,
      FORM_BUTTON_TITLE_QUOTE_RECEIVED,
      DIRECT_REQUEST_FOR_QUOTE_ACTION_VOID,
      DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_DRAFT,
      DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_SEND_EMAIL,
      DIRECT_REQUEST_FOR_QUOTE_ACTION_QUOTE_RECEIVED,
    }
  },
}
</script>
