<template>
  <div class="form-wrapper">
    <stock-items
      :new-quote-product-id="theProductId"
      class="mb-4"
    />
    <other-charges is-new-direct />
  </div>
</template>
<script>

// eslint-disable-next-line import/named
import OtherCharges from '../../common/OtherCharges.vue'
import StockItems from './StockItems.vue'

export default {
  name: 'ItemsPurchase',
  components: {
    StockItems,
    OtherCharges,
  },
  data() {
    return {
      theProductId: this.$route.query?.product_id,
    }
  },
}
</script>
