<template>
  <div>
    <div>
      <error-alert
        :fields="{}"
        :error="onError"
      />
      <b-form>
        <validation-observer ref="purchaseQuoteForm">
          <order-information
            :supplier-id="theSupplierId"
            class="mb-1"
          />
          <dispatch class="mb-1" />
          <items-purchase />
        </validation-observer>
      </b-form>
    </div>
    <form-bottom
      :form-ref="$refs"
      @onSubmitSuccess="onSubmitSuccess"
      @clear="onClear"
    />
    <b-s-warning-modal
      ref="confirm-modal"
      @onNextTick="onClear"
    />
  </div>
</template>

<script>
// Components
import { BForm } from 'bootstrap-vue'
import { ValidationObserver } from 'vee-validate'
import ErrorAlert from '@/views/components/ErrorAlert.vue'
import minConfig from '@/views/main/orders/view/purchase/config'
import { mapGetters } from 'vuex'
import config from './config'
import ItemsPurchase from './components/Items.vue'
import OrderInformation from './components/OrderInformation.vue'
import Dispatch from './components/Dispatch.vue'
import FormBottom from './components/FormBottom.vue'

export default {
  name: 'CreateForm',
  components: {
    OrderInformation,
    ItemsPurchase,
    Dispatch,
    BForm,
    FormBottom,
    ErrorAlert,
    ValidationObserver,
  },

  data() {
    return {
      error: {},
      onSubmit: false,
      theSupplierId: this.$route.query?.supplier_id,
    }
  },
  computed: {
    ...mapGetters('purchase-cr', ['getTotalDataOfForms']),
    onError() {
      return this.$store.state[this.MODULE_NAME].onError
    },
    purchaseFormClone() {
      return this.$store.state[this.MODULE_NAME_CLONE].purchaseFormClone
    },
    isFormChanged() {
      return JSON.stringify(this.getTotalDataOfForms) !== this.purchaseFormClone
    },
  },
  methods: {
    onClear() {
      for (let i = 0; i < this.storeMutationsList.length; i++) {
        this.$store.commit(`${this.MODULE_NAME}/${this.storeMutationsList[i].mutation}`, this.storeMutationsList[i].payload)
      }
      this.onSubmit = true
    },
    onSubmitSuccess() {
      this.onSubmit = true
    },
    showConfirmationModal(next) {
      if (this.$refs['confirm-modal']) {
        this.$refs['confirm-modal'].confirmWarning(next, this.isFormChanged, this.onSubmit)
      }
    },
  },
  setup() {
    const MODULE_NAME = 'purchase-cr'
    const MODULE_NAME_CLONE = 'cloneData'

    const {
      mapDate,
    } = minConfig()

    const {
      storeMutationsList,
    } = config()

    return {
      mapDate,
      MODULE_NAME,
      MODULE_NAME_CLONE,
      storeMutationsList,
    }
  },
}
</script>
