<template>
  <div>
    <portal to="body-top">
      <h3 class="text-center font-weight-bolder mb-1">
        {{ $t(CREATE_PAGE_TITLE) }}
      </h3>
      <quote-status-bar :quote-status-bar="quoteStatusBar" />
    </portal>
    <create-form
      ref="create-form"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
// eslint-disable-next-line import/no-cycle
import QuoteStatusBar from '@/views/main/orders/components/use-as-global/QuoteStatusBar.vue'
import config from '../config'

// Components
import CreateForm from '../components/create/direct-request-for-quote/Form.vue'

export default {
  name: 'Create',
  components: {
    CreateForm,
    QuoteStatusBar,
  },
  data() {
    return {
      isWatchingWarehouse: true,
    }
  },
  computed: {
    ...mapGetters('purchase-cr', ['getTotalDataOfForms']),
    warehouse() {
      return this.$store.state.app.warehouse
    },
    quote() {
      return this.$store.state[this.MODULE_NAME].quote
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.$refs['create-form']) {
      this.$refs['create-form'].showConfirmationModal(next)
    } else {
      next()
    }
  },
  watch: {
    warehouse(val) {
      if (val && this.isWatchingWarehouse && !this.quote.id) {
        this.$store.commit(`${this.MODULE_NAME}/SET_DISPATCH_AND_RETURN_FORM`, {
          receiving_warehouse: { ...val },
        })
        this.$store.commit(`${this.MODULE_NAME_CLONE}/SET_PURCHASE_FORM_CLONE`, { receiving_warehouse: { ...val }, ...this.getTotalDataOfForms })
        this.isWatchingWarehouse = false
      }
    },
  },
  created() {
    this.$store.commit(`${this.MODULE_NAME}/GET`, { })
    this.$store.commit(`${this.MODULE_NAME_CLONE}/SET_PURCHASE_FORM_CLONE`, this.getTotalDataOfForms)
    this.$store.commit(`${this.MODULE_NAME}/SET_ON_ERROR`, {})
    this.$store.commit(`${this.MODULE_NAME}/SET_ORDER_ITEMS_OTHER_CHARGES_TABLE`, [])
    this.$store.commit(`${this.MODULE_NAME}/LIST_ATTACHMENTS`, [])
    this.$store.commit(`${this.MODULE_NAME}/SET_ORDER_ITEMS_RENTAL_TABLE`, {
      products: [],
    })
    this.$store.commit(`${this.MODULE_NAME}/SET_ORDERS_INFORMATION_FORM`, {})
    this.$store.commit(`${this.MODULE_NAME}/SET_CUSTOMER_INFORMATION_FORM`, {
      attachments: [],
    })
    this.$store.commit(`${this.MODULE_NAME}/SET_DISPATCH_AND_RETURN_FORM`, {})
  },
  setup() {
    const {
      CREATE_PAGE_TITLE, MODULE_NAME, MODULE_NAME_CLONE, quoteStatusBar,
    } = config()

    return {
      MODULE_NAME,
      quoteStatusBar,
      CREATE_PAGE_TITLE,
      MODULE_NAME_CLONE,
    }
  },
}
</script>
